import React, { useState, useEffect } from "react";
import style from "./Learning.module.css";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { HiOutlineViewGrid } from "react-icons/hi";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { baseUrl } from "../Url";
import Checkbox from "@mui/material/Checkbox";
import { PiExam } from "react-icons/pi";
import { BsBookHalf } from "react-icons/bs";
import MuiAlert from "@mui/material/Alert";
import FormGroup from "@mui/material/FormGroup";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { GrUserAdmin } from "react-icons/gr";
import dayjs from "dayjs";
import { RiDeleteBin5Line } from "react-icons/ri";
import { MdEditSquare } from "react-icons/md";

const Learning = () => {
  const navigate = useNavigate();
  const key = JSON.parse(localStorage.getItem("access-token"));
  const [organisationDetail, setOrganisationDetail] = useState([]);
  const [clubList, setClubList] = useState([]);
  const [buttonShow, setButtonshow] = useState(false);
  const [mainCategoryList, setMainCategoryList] = useState([]);
  const [mainCategoryShow, setMainCategoryShow] = useState(false);
  const [selectedOrgId, setSelectedOrgId] = useState("");
  const [mainCategoryCreate, setMainCategoryCreate] = useState(false);
  const [mainCategoryName, setMainCategoryName] = useState("");
  const [mainCategoryDescription, setMainCategoryDescription] = useState("");
  const [subCategoryCreate, setSubCategoryCreate] = useState(false);
  const [subCategoryCreatePopup, setSubCategoryCreatePopup] = useState(false);
  const [subShow, setSubShow] = useState(false);
  const [subCategoryName, setSubCategoryName] = useState("");
  const [subCategoryDescription, setSubCategoryDescription] = useState("");
  const [parentId, setParentId] = useState("");
  const [clubReadOnly, setClubReadOnly] = useState(true);
  const [topicButtonShow, setTopicButtonShow] = useState(true);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [topicList, setTopicList] = useState([]);
  const [topicCreation, setTopicCreation] = useState(false);
  const [topicShow, setTopicShow] = useState(false);
  const [categoryButtonShow, setcategoryButtonShow] = useState(true);
  const [topicName, setTopicName] = useState("");
  const [topiEdit, setTopicEdit] = useState(false);
  const [editData, setEditData] = useState("");
  const [editId, setEditId] = useState("");
  const [mainTopicId, setMainTopicId] = useState("");
  const [topicDelete, setTopicDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mainCategoryEdit, setMainCategoryEdit] = useState(false);
  const [mainCategoryDelete, setMainCategoryDelete] = useState(false);
  const [subCategoryEdit, setSubCategoryEdit] = useState(false);
  const [subCategoryDelete, setSubCategoryDelete] = useState(false);
  const [editMainCategoryName, setEditMainCategoryName] = useState("");
  const [
    editMainCategoryDescription,
    setEditMainCategoryDescription,
  ] = useState("");
  const [editSubCategoryName, setEditSubCategoryName] = useState("");
  const [editSubCategoryDescription, setEditSubCategoryDescription] = useState(
    ""
  );
  const [mainCategoryID, setMainCategoryID] = useState("");
  const [subCategoryID, setSubCategoryID] = useState("");
  const adminStatus = localStorage.getItem("adminStatus");
  const { profile } = useSelector((state) => state.profileData);
  const [deleteName, setDeleteName] = useState("");
  const [deleteTopicName, setDeleteTopicName] = useState("");
  const [deleteSubName, setDeleteSubName] = useState("");
  const [currentPath, setCurrentPath] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [divide, setDivide] = useState(false);
  const [categoryCreationFail, setCategoryCreationFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [categoryCreation, setCategoryCreation] = useState(false);
  const [categoryDeletion, setCategoryDeletion] = useState(false);
  const [categoryUpdation, setCategoryUpdation] = useState(false);
  const [subCategoryCreation, setSubCategoryCreation] = useState(false);
  const [subCategoryDeletion, setSubCategoryDeletion] = useState(false);
  const [subCategoryUpdation, setSubCategoryUpdation] = useState(false);
  const [topicCreationSuccess, setTopicCreationSuccess] = useState(false);
  const [topicDeletion, setTopicDeletion] = useState(false);
  const [topicUpdation, setTopicUpdation] = useState(false);
  const [courseMapping, setCourseMapping] = useState(false);
  const [selectedClubs, setSelectedClubs] = useState([]);
  const [mappingSuccess, setMappingSuccess] = useState(false);
  const [showExamButton, setShowExamButton] = useState(false);
  const [examCreate, setExamCreate] = useState(false);
  const [examName, setExamName] = useState("");
  const [examTopicList, setExamTopicList] = useState([]);
  const [examCreationSuccess, setExamCreationSuccess] = useState(false);
  const [examUpdationSuccess, setExamUpdationSuccess] = useState(false);
  const [examDeletionSuccess, setExamDeletionSuccess] = useState(false);
  const [editExam, setEditExam] = useState(false);
  const [deleteExam, setDeleteExam] = useState(false);
  const [examID, setExamID] = useState("");
  const [editExamName, setEditExamName] = useState("");
  const [editExamTopicList, setEditExamTopicList] = useState([]);
  const [club_id, setClub_ID] = useState([]);
  const [examStatus, setExamStatus] = useState("draft");
  const [editExamStatus, setEditExamStatus] = useState("");
  const [moduleCreatePopup, setModuleCreatePopup] = useState(false);
  const [moduleEditPopup, setModuleEditPopup] = useState(false);
  const [moduleName, setModuleName] = useState("");
  const [moduleDescription, setModuleDescription] = useState("");
  const [moduleState, setModuleState] = useState("disabled");
  const [moduleStatus, setModuleStatus] = useState("inactive");
  const [moduleType, setModuleType] = useState("free");
  const [moduleEditName, setModuleEditName] = useState("");
  const [moduleEditDescription, setModuleEditDescription] = useState("");
  const [moduleEditState, setModuleEditState] = useState("");
  const [moduleEditStatus, setModuleEditStatus] = useState("");
  const [moduleEditType, setModuleEditType] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [moduleList, setModuleList] = useState([]);
  const [selectedModule, setSelectedModule] = useState("");

  const [scheduleExamModal, setScheduleExamModal] = useState(false);
  const [examType, setExamType] = useState("");
  const [scheduleExamName, setScheduleExamName] = useState("");
  const [selectedDay, setSelectedDay] = useState(dayjs().format("dddd"));
  const [noOfQuestions, setNoOfQuestions] = useState("");
  const [scheduleExamCreation, setScheduleExamCreation] = useState(false);
  const [scheduleExamFail, setScheduleExamFail] = useState(false);
  const [scheduleNameEmpty, setScheduleNameEmpty] = useState(false);
  const [scheduleExamEditPopup, setScheduleExamEditPopup] = useState(false);
  const [scheduleExamData, setScheduleExamData] = useState([]);
  const [scheduleExamUpdate, setScheduleExamUpdate] = useState(false);
  const [formData, setFormData] = useState({
    org_id: "",
    category_id: "",
    scheduledexam_type: "",
    scheduledexam_name: "",
    selected_day: "",
    no_of_questions: "",
  });
  const [deleteScheduleExam, setDeleteScheduleExam] = useState(false);
  const [scheduleExamDeleteSuccess, setScheduleExamDeleteSuccess] = useState(
    false
  );

  const userRole = localStorage.getItem("userRole");

  useEffect(() => {
    console.log(club_id);
  }, [club_id]);

  const handleExamTypeChange = (event) => {
    setExamType(event.target.value);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleDayChange = (event) => {
    setSelectedDay(event.target.value);
  };

  const handleNoOfQuestionsChange = (event) => {
    setNoOfQuestions(event.target.value);
  };

  const daysOfWeek = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleInput = (event) => {
    const value = event.target.value;
    if (value < 0) {
      event.target.value = 0;
    }
  };

  const handleStatusChange = (event) => {
    setModuleStatus(event.target.checked ? "active" : "inactive");
  };

  const handleStateChange = (event) => {
    setModuleState(event.target.checked ? "enabled" : "disabled");
  };

  const handleTypeChange = (event) => {
    setModuleType(event.target.checked ? "paid" : "free");
  };

  const handleEditStatusChange = (event) => {
    const newStatus = event.target.checked ? "active" : "inactive";
    setModuleEditStatus(newStatus);
    console.log("New Status:", newStatus);
  };

  const handleEditStateChange = (event) => {
    const newState = event.target.checked ? "enabled" : "disabled";
    setModuleEditState(newState);
    console.log("New State:", newState);
  };

  const handleExamNameChange = (event) => {
    setScheduleExamName(event.target.value);
  };

  const handleEditTypeChange = (event) => {
    const newType = event.target.checked ? "paid" : "free";
    setModuleEditType(newType);
    console.log("New Type:", newType);
  };

  const handleCategoryClick = (category) => {
    setSelectedCategories([...selectedCategories, category]);
    const categoryId = category.id;
    console.log("Selected Category ID:", categoryId);
  };

  const handleBreadcrumbClick = (id, index) => {
    setSelectedCategories(selectedCategories.slice(0, index));
    subcategoryLinkGet(id);
    console.log("Clicked Breadcrumb Category ID:", id);
  };

  const handleCheckboxChange = (clubID) => {
    const newSelectedClubs = selectedClubs.includes(clubID)
      ? selectedClubs.filter((id) => id !== clubID)
      : [...selectedClubs, clubID];

    setSelectedClubs(newSelectedClubs);
  };

  const handleTopicSelection = (topicid) => {
    if (Array.isArray(examTopicList)) {
      if (examTopicList.includes(topicid)) {
        setExamTopicList(examTopicList.filter((id) => id !== topicid));
      } else {
        setExamTopicList([...examTopicList, topicid]);
      }
    } else {
      setExamTopicList([examTopicList, topicid]);
    }
  };
  const handleEditTopicSelection = (topicid) => {
    if (Array.isArray(editExamTopicList)) {
      if (editExamTopicList.includes(topicid)) {
        setEditExamTopicList(editExamTopicList.filter((id) => id !== topicid));
      } else {
        setEditExamTopicList([...editExamTopicList, topicid]);
      }
    } else {
      setEditExamTopicList([editExamTopicList, topicid]);
    }
  };

  const removeItemsFromLocalStorage = () => {
    const itemsToRemove = [
      "SelectedOrgID",
      "SelectedTopicID",
      "SelectedsubTopicID",
      "SelectedsubTopicName",
    ];

    itemsToRemove.forEach((item) => localStorage.removeItem(item));
  };
  //Organization list API
  async function OrgGet() {
    const userId = localStorage.getItem("adminUserId");
    await fetch(`${baseUrl}/org/get/admin/per_users`, {
      method: "POST",
      body: JSON.stringify({
        user_id: userId,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "orgData");
        setOrganisationDetail(data.data);
      });
  }

  useEffect(() => {
    const org_id = localStorage.getItem("selectedOrgID");
    const category_id = localStorage.getItem("categoryID");
  
    fetch(`${baseUrl}/module/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ org_id: org_id, category_id: category_id }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status && data.status_code === 200) {
          setModuleList(data.data);
        } else {
          console.error("Failed to fetch module list");
        }
      })
      .catch((error) => console.error("Error fetching module list:", error));
  }, []);
  

  const handleModuleChange = (event) => {
    const selectedModuleId = event.target.value;
    const selectedModuleName = moduleList.find(
      (module) => module.module_id === selectedModuleId
    )?.module_name;

    // Set module ID and name to local storage
    localStorage.setItem("selectedModuleId", selectedModuleId);
    localStorage.setItem("selectedModuleName", selectedModuleName);

    setSelectedModule(selectedModuleId);
  };

  //CLub list API
  async function ClubGet(OrgId) {
    await fetch(`${baseUrl}/club/list`, {
      method: "POST",
      body: JSON.stringify({
        OrganizationID: OrgId,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setClubList(data.data);
      });
  }

  const validateCreation = () => {
    if (!mainCategoryName) {
      setErrorMessage("Category Name is required");
      return false;
    }
    if (!mainCategoryDescription) {
      setErrorMessage("Category Description is required");
      return false;
    }

    return true;
  };

  //main category creation API

  async function mainCategory_Create() {
    if (!validateCreation()) {
      setCategoryCreationFail(true);
      return;
    } else {
      await fetch(`${baseUrl}/categories/create`, {
        method: "POST",
        body: JSON.stringify({
          org_id: selectedOrgId,
          parent_id: "",
          Name: mainCategoryName,
          Description: mainCategoryDescription,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${key}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.status === true) {
            categoryGet();
            setMainCategoryCreate(false);
            setCategoryCreation(true);
          }
        });
    }
  }

  const validateUpdation = () => {
    if (!editMainCategoryName) {
      setErrorMessage("Category Name is required");
      return false;
    }
    if (!editMainCategoryDescription) {
      setErrorMessage("Category Description is required");
      return false;
    }

    return true;
  };

  //edit maincategory
  async function editMainCategory() {
    if (!validateUpdation()) {
      setCategoryCreationFail(true);
      return;
    } else {
      await fetch(`${baseUrl}/categories/edit`, {
        method: "POST",
        body: JSON.stringify({
          category_id: mainCategoryID,
          Name: editMainCategoryName,
          Description: editMainCategoryDescription,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${key}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.status === true) {
            categoryGet();
            setMainCategoryEdit(false);
            setMainCategoryID("");
            setEditMainCategoryDescription("");
            setEditMainCategoryName("");
            setCategoryUpdation(true);
          }
        });
    }
  }
  // ------- delete category ------- //
  async function deleteMainCategory() {
    await fetch(`${baseUrl}/categories/delete`, {
      method: "POST",
      body: JSON.stringify({
        category_id: mainCategoryID,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          categoryGet();
          setMainCategoryDelete(false);
          setMainCategoryID("");
          setCategoryDeletion(true);
        }
      });
  }

  //Categories under club and org list API

  async function categoryGet(id) {
    await fetch(`${baseUrl}/main/categories/get`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: id ? id : selectedOrgId,
        type: "admin",
        // club_id: ClubId,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setMainCategoryList(data.data);
        setMainCategoryShow(true);
        setLoading(false);
      });
  }

  const validateSubCategoryCreation = () => {
    if (!subCategoryName) {
      setErrorMessage("Category Name is required");
      return false;
    }
    if (!subCategoryDescription) {
      setErrorMessage("Category Description is required");
      return false;
    }

    return true;
  };

  //Course Map
  async function mapCourse() {
    const subCatId = localStorage.getItem("categoryID");
    await fetch(`${baseUrl}/map/batch/to/course`, {
      method: "POST",
      body: JSON.stringify({
        club_id: selectedClubs,
        category_id: subCatId,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setCourseMapping(false);
          setSelectedClubs([]);
          setMappingSuccess(true);
          categoryGet(selectedOrgId);
        }
      });
  }

  //sub category creation API
  async function subCatregory_create() {
    const subCatId = localStorage.getItem("categoryID");
    const ClubId = localStorage.getItem("clubid");

    if (!validateSubCategoryCreation()) {
      setCategoryCreationFail(true);
      return;
    } else {
      await fetch(`${baseUrl}/categories/create`, {
        method: "POST",
        body: JSON.stringify({
          org_id: selectedOrgId,
          parent_id: subCatId,
          Name: subCategoryName,
          Description: subCategoryDescription,
          club_id: ClubId,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${key}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.status === true) {
            subcategoryGet();
            setSubCategoryCreatePopup(false);
            setSubCategoryCreation(true);
          }
        });
    }
  }

  //sub category list API
  async function subcategoryGet() {
    const subCatId = localStorage.getItem("categoryID");

    await fetch(`${baseUrl}/sub/categories/get`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: selectedOrgId,
        id: subCatId,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        console.log(selectedCategories);
        if (data.category === true) {
          setSubShow(true);
          setTopicButtonShow(false);
          setSubCategoryList(data.data);
          setcategoryButtonShow(true);
          setTopicShow(false);
        } else if (data.topic === true) {
          topicGet();
          setSubShow(false);
          setTopicButtonShow(true);
          setTopicShow(true);
          setcategoryButtonShow(false);
        } else {
          setSubShow(true);
          setTopicButtonShow(true);
          setSubShow(false);
        }
      });
  }

  async function subcategoryLinkGet(id) {
    await fetch(`${baseUrl}/sub/categories/get`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: selectedOrgId,
        id: id,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        console.log(selectedCategories);
        if (data.category === true) {
          setSubShow(true);
          setTopicButtonShow(false);
          setSubCategoryList(data.data);
          setcategoryButtonShow(true);
          setTopicShow(false);
        } else if (data.topic === true) {
          topicGet();
          setSubShow(false);
          setTopicButtonShow(true);
          setTopicShow(true);
          setcategoryButtonShow(false);
        } else {
          setSubShow(true);
          setTopicButtonShow(true);
          setSubShow(false);
        }
      });
  }

  const validateSubCategoryUpdation = () => {
    if (!editSubCategoryName) {
      setErrorMessage("Category Name is required");
      return false;
    }
    if (!editSubCategoryDescription) {
      setErrorMessage("Category Description is required");
      return false;
    }

    return true;
  };

  //edit subcategory
  async function editSubCategory() {
    if (!validateSubCategoryUpdation()) {
      setCategoryCreationFail(true);
      return;
    } else {
      await fetch(`${baseUrl}/categories/edit`, {
        method: "POST",
        body: JSON.stringify({
          category_id: subCategoryID,
          Name: editSubCategoryName,
          Description: editSubCategoryDescription,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${key}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.status === true) {
            subcategoryGet();
            setSubCategoryEdit(false);
            setSubCategoryID("");
            setEditSubCategoryDescription("");
            setEditSubCategoryName("");
            setSubCategoryUpdation(true);
          }
        });
    }
  }
  //delete subcategory
  async function deleteSubCategory() {
    await fetch(`${baseUrl}/categories/delete`, {
      method: "POST",
      body: JSON.stringify({
        category_id: subCategoryID,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          subcategoryGet();
          setSubCategoryDelete(false);
          setSubCategoryID("");
          setSubCategoryDeletion(true);
        }
      });
  }

  const validateTopicCreation = () => {
    if (!topicName) {
      setErrorMessage("Topic Name is required");
      return false;
    }

    return true;
  };

  //topic Creation
  async function topicAdd() {
    const subCatId = localStorage.getItem("categoryID");
    const selectedModuleId = localStorage.getItem("selectedModuleId");
    const selectedModuleName = localStorage.getItem("selectedModuleName");

    if (!validateTopicCreation()) {
      setCategoryCreationFail(true);
      return;
    } else {
      await fetch(`${baseUrl}/topics/create`, {
        method: "POST",
        body: JSON.stringify({
          org_id: selectedOrgId,
          category_id: subCatId,
          module_id: selectedModuleId,
          module_name: selectedModuleName,
          topics: [topicName],
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${key}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.status === true) {
            topicGet();
            setTopicShow(true);
            setTopicCreation(false);
            setTopicCreationSuccess(true);
            setSelectedModule("");
            localStorage.removeItem("selectedModuleId");
            localStorage.removeItem("selectedModuleName");
          }
        });
    }
  }

  // for getting topics created
  async function topicGet() {
    const subCatId = localStorage.getItem("categoryID");
    await fetch(`${baseUrl}/topic/get`, {
      method: "POST",
      body: JSON.stringify({
        org_id: selectedOrgId,
        category_id: subCatId,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setMainTopicId(data.data[0].topic_id);
        setTopicList(data.data);
        console.log(data.data);

        if (data.data[0].topics.length > 0) {
          setShowExamButton(true);
        }
      });
  }

  const validateTopicUpdation = () => {
    if (!editData) {
      setErrorMessage("Topic Name is required");
      return false;
    }

    return true;
  };

  //topic edit
  async function editTopic() {
    const orgID = localStorage.getItem("SelectedOrgID");
    const topicId = localStorage.getItem("SelectedTopicID");
    const subTopic = localStorage.getItem("SelectedsubTopicID");

    if (!validateTopicUpdation()) {
      setCategoryCreationFail(true);
      return;
    } else {
      await fetch(`${baseUrl}/topics/edit`, {
        method: "POST",
        body: JSON.stringify({
          org_id: orgID,
          topic_id: topicId, // Ensure this is correctly set for the selected module
          id: subTopic,
          value: editData,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${key}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.status === true) {
            topicGet(); // Ensure this updates the topics correctly
            setEditData("");
            setTopicEdit(false);
            removeItemsFromLocalStorage();
            setTopicUpdation(true);
          }
        });
    }
  }

  // ------- topic delete -------
  async function deleteTopic() {
    const subCatId = localStorage.getItem("categoryID");
    const orgID = localStorage.getItem("SelectedOrgID");
    const topicId = localStorage.getItem("SelectedTopicID");
    const subTopic = localStorage.getItem("SelectedsubTopicID");

    await fetch(`${baseUrl}/topics/delete`, {
      method: "POST",
      body: JSON.stringify({
        org_id: orgID,
        category_id: subCatId,
        topic_id: topicId, // Ensure this is correctly set for the selected module
        id: subTopic,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          topicGet(); // Ensure this updates the topics correctly
          setTopicDeletion(true);
          removeItemsFromLocalStorage();
        }
      });
  }

  const validateExamCreation = () => {
    if (!examName) {
      setErrorMessage("Topic Name is required");
      return false;
    }
    if (examTopicList.length < 0) {
      setErrorMessage("Select atleast one topic");
      return false;
    }

    return true;
  };

  //exam Creation
  async function examCreation() {
    const ClubId = localStorage.getItem("clubid");
    if (!validateExamCreation()) {
      setCategoryCreationFail(true);
      return;
    } else {
      await fetch(`${baseUrl}/exam/generation`, {
        method: "POST",
        body: JSON.stringify({
          org_id: selectedOrgId,
          club_id: ClubId,
          exam_name: examName,
          main_topic_id: mainTopicId,
          topics: examTopicList,
          publish_status: examStatus,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${key}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.status === true) {
            topicGet();
            setExamCreate(false);
            setExamCreationSuccess(true);
            setExamName("");
            setExamTopicList([]);
          }
        });
    }
  }
  const validateExamUpdation = () => {
    if (!editExamName) {
      setErrorMessage("Topic Name is required");
      return false;
    }
    if (editExamTopicList.length < 0) {
      setErrorMessage("Select atleast one topic");
      return false;
    }

    return true;
  };

  //exam Creation
  async function examUpdation() {
    if (!validateExamUpdation()) {
      setCategoryCreationFail(true);
      return;
    } else {
      await fetch(`${baseUrl}/exam/edit`, {
        method: "POST",
        body: JSON.stringify({
          topic_id: mainTopicId,
          id: examID,
          value: editExamName,
          topics: editExamTopicList,
          publish_status: editExamStatus,
          org_id: selectedOrgId,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${key}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.status === true) {
            topicGet();
            setEditExam(false);
            setExamUpdationSuccess(true);
          }
        });
    }
  }

  async function examDelete() {
    await fetch(`${baseUrl}/exam/delete`, {
      method: "POST",
      body: JSON.stringify({
        topic_id: mainTopicId,
        id: examID,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          topicGet();
          setDeleteExam(false);
          setExamDeletionSuccess(true);
        }
      });
  }

  useEffect(() => {
    if (
      (adminStatus === false || adminStatus === "false") &&
      userRole !== "Partner"
    ) {
      const orgid = profile?.org_id;
      if (orgid !== undefined && orgid !== "") {
        // ClubGet(orgid);
        setSelectedOrgId(orgid);
        categoryGet(orgid);
      }
      setButtonshow(true);
    } else {
      OrgGet();
      setButtonshow(false);
    }
  }, [adminStatus, profile]);

  // API FOR MODULE CREATION
  const addModule = async () => {
    const category_id = localStorage.getItem("categoryID");

    try {
      const response = await fetch(`${baseUrl}/module/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          org_id: selectedOrgId,
          category_id,
          module_name: moduleName,
          module_description: moduleDescription,
          module_state: moduleState,
          module_status: moduleStatus,
          module_type: moduleType,
        }),
      });

      const data = await response.json();

      if (data.status) {
        console.log("Module created successfully:", data.data);
        setModuleCreatePopup(false);
        setModuleName("");
        setModuleDescription("");
        setModuleState("disabled");
        setModuleStatus("inactive");
        setModuleType("free");
        setSnackbarMessage("Module added");
        setSnackbarOpen(true);

        fetchModuleList();
      } else {
        console.error("Error creating module:", data.description);
      }
    } catch (error) {
      console.error("Error creating module:", error);
    }
  };

  // Function to fetch the module list
  const fetchModuleList = () => {
    const org_id = localStorage.getItem("selectedOrgID");
    const category_id = localStorage.getItem("categoryID");

    fetch(`${baseUrl}/module/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ org_id: org_id, category_id: category_id }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status && data.status_code === 200) {
          setModuleList(data.data);
        } else {
          console.error("Failed to fetch module list");
        }
      })
      .catch((error) => console.error("Error fetching module list:", error));
  };

  // Function to fetch the module details
  const fetchModuleData = async () => {
    const module_id = localStorage.getItem("moduleId");
    try {
      const response = await fetch(`${baseUrl}/module/list/single`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ module_id }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log(data);
      const moduleData = data.data;
      setModuleEditName(moduleData.module_name);
      setModuleEditDescription(moduleData.module_description);
      setModuleEditState(moduleData.module_state);
      setModuleEditStatus(moduleData.module_status);
      setModuleEditType(moduleData.module_type);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  useEffect(() => {
    fetchModuleData();
  }, []);

  useEffect(() => {
    if (
      moduleEditStatus !== "" &&
      moduleEditState !== "" &&
      moduleEditType !== ""
    ) {
      updateModule();
    }
  }, [moduleEditStatus, moduleEditState, moduleEditType]);

  const updateModule = async () => {
    const module_id = localStorage.getItem("moduleId");

    const updatedModuleData = {
      module_id,
      module_name: moduleEditName,
      module_description: moduleEditDescription,
      module_status: moduleEditStatus,
      module_type: moduleEditType,
      module_state: moduleEditState,
    };

    try {
      const response = await fetch(`${baseUrl}/module/update`, {
        method: "POST",
        body: JSON.stringify(updatedModuleData),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${key}`,
        },
      });
      const data = await response.json();
      if (data.status && data.status_code === 200) {
        console.log("Module updated successfully");
      } else {
        console.error("Failed to update module");
      }
    } catch (error) {
      console.error("Error updating module:", error);
    }
  };

  const scheduleExams = async () => {
    try {
      const orgId = localStorage.getItem("selectedOrgID");
      const category_id = localStorage.getItem("categoryID");

      if (!scheduleExamName.trim()) {
        setScheduleNameEmpty(true);
        return;
      }

      let requestBody = {
        org_id: orgId,
        category_id: category_id,
        scheduledexam_type: examType,
        scheduledexam_name: scheduleExamName,
        no_of_questions: noOfQuestions,
      };

      if (examType === "daily") {
        requestBody.selected_day = "everyday";
      } else {
        requestBody.selected_day = selectedDay;
      }

      const response = await fetch(`${baseUrl}/schedule/exam/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();

      if (data.status) {
        setScheduleExamCreation(true);
        fetchScheduledExams();
        setExamType("daily");
        setSelectedDay("");
        setNoOfQuestions("");
        setScheduleExamName("");
      } else {
        setScheduleExamFail(true);
      }
      setScheduleExamModal(false);
    } catch (error) {
      setScheduleExamFail(true);
    }
  };

  const fetchScheduledExams = async () => {
    const orgId = localStorage.getItem("selectedOrgID");
    const categoryId = localStorage.getItem("categoryID");

    if (!orgId || !categoryId) {
      return;
    }

    try {
      const response = await fetch(`${baseUrl}/schedule/exam/list`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          org_id: orgId,
          category_id: categoryId,
        }),
      });

      const data = await response.json();
      if (data.status) {
        setScheduleExamData(data.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchScheduledExams();
  }, []);

  const scheduledexam_id = localStorage.getItem("scheduledexam_id");

  useEffect(() => {
    const fetchScheduledExamData = async () => {
      try {
        const response = await fetch(`${baseUrl}/scheduled/exam/single`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ scheduledexam_id }),
        });

        const data = await response.json();

        if (data.status) {
          const examData = data.data;
          setFormData({
            scheduledexam_type: examData.scheduledexam_type,
            selected_day: examData.selected_day,
            no_of_questions: examData.no_of_questions,
            scheduledexam_name: examData.scheduledexam_name,
          });
        } else {
          // alert(data.description);
        }
      } catch (error) {
        console.error("Error fetching scheduled exam data:", error);
      }
    };

    if (scheduledexam_id) {
      fetchScheduledExamData();
    } else {
      console.log("Scheduled Exam ID is missing");
    }
  }, [scheduledexam_id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const scheduleExamEdit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${baseUrl}/schedule/exam/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ scheduledexam_id, ...formData }),
      });

      const data = await response.json();

      if (data.status) {
        setScheduleExamUpdate(true);
        setScheduleExamEditPopup(false);
        fetchScheduledExams();
      } else {
        console.log(data.description);
      }
    } catch (error) {
      console.error("Error updating scheduled exam data:", error);
    }
  };

  const handleDeleteScheduledExam = async () => {
    const scheduledexam_id = localStorage.getItem("scheduledexam_id");

    if (!scheduledexam_id) {
      console.log("Scheduled Exam ID not found in local storage.");
      return;
    }

    try {
      const response = await fetch(`${baseUrl}/schedule/exam/delete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ scheduledexam_id }),
      });

      const result = await response.json();

      if (result.status) {
        setScheduleExamDeleteSuccess(true);
        setDeleteScheduleExam(false);
        fetchScheduledExams();
      } else {
        console.log(`Error: ${result.description}`);
      }
    } catch (error) {
      console.log(`An error occurred: ${error.message}`);
    }
  };

  return (
    <div className={style.Container}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={
          categoryCreation ||
          categoryDeletion ||
          categoryUpdation ||
          subCategoryCreation ||
          subCategoryDeletion ||
          subCategoryUpdation ||
          topicCreationSuccess ||
          topicDeletion ||
          topicUpdation ||
          mappingSuccess ||
          examCreationSuccess ||
          examDeletionSuccess ||
          examUpdationSuccess ||
          scheduleExamCreation ||
          scheduleExamUpdate ||
          scheduleExamDeleteSuccess
        }
        sx={{ zIndex: 999999, width: "22rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setCategoryCreation(false);
          setCategoryUpdation(false);
          setCategoryDeletion(false);
          setSubCategoryCreation(false);
          setSubCategoryDeletion(false);
          setSubCategoryUpdation(false);
          setTopicCreationSuccess(false);
          setTopicDeletion(false);
          setTopicUpdation(false);
          setMappingSuccess(false);
          setExamCreationSuccess(false);
          setExamDeletionSuccess(false);
          setExamUpdationSuccess(false);
          setScheduleExamCreation(false);
          setScheduleExamUpdate(false);
          setScheduleExamDeleteSuccess(false);
        }}
      >
        <Alert
          severity="success"
          variant="filled"
          sx={{ width: "100%", fontSize: "1.1rem" }}
        >
          {categoryCreation
            ? "Category created successfully"
            : categoryDeletion
            ? "Category deleted successfully"
            : categoryUpdation
            ? "Category updated successfully"
            : subCategoryCreation
            ? "Sub Category created successfully"
            : subCategoryDeletion
            ? "Sub Category deleted successfully"
            : subCategoryUpdation
            ? "Sub Category updated successfully"
            : topicCreationSuccess
            ? "Topic created successfully"
            : topicDeletion
            ? "Topic deleted successfully"
            : topicUpdation
            ? "Topic updated successfully"
            : mappingSuccess
            ? "Learning mapped successfully"
            : examCreationSuccess
            ? "Exam created successfully"
            : examDeletionSuccess
            ? "Exam deleted successfully"
            : examUpdationSuccess
            ? "Exam updated successfully"
            : scheduleExamCreation
            ? "Exam scheduled successfully"
            : scheduleExamUpdate
            ? "Scheduled Exam Updated Successfully"
            : scheduleExamDeleteSuccess
            ? "Scheduled Exam deleted successfully"
            : ""}
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={categoryCreationFail || scheduleNameEmpty || scheduleExamFail}
        sx={{ width: "25rem", zIndex: 999999 }}
        autoHideDuration={3000}
        onClose={() => {
          setCategoryCreationFail(false);
          setScheduleNameEmpty(false);
          setScheduleExamFail(false);
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: "100%", fontSize: "1.1rem" }}
        >
          {categoryCreationFail
            ? "catagory creation failed"
            : scheduleNameEmpty
            ? "Exam Name cannot be empty"
            : scheduleExamFail
            ? "Error sheduling Exam"
            : ""}
        </Alert>
      </Snackbar>
      <div className={style.Header}>
        <div className={style.HeaderText}>
          <p>Learning Management</p>
        </div>
        {buttonShow ? (
          <div className={style.HeadButton}>
            <button
              onClick={() => {
                setMainCategoryCreate(true);
              }}
            >
              Create Course{" "}
            </button>
          </div>
        ) : (
          ""
        )}
      </div>

      <div className={style.secondHead}>
        {(adminStatus === false || adminStatus === "false") &&
        userRole !== "Partner" ? (
          ""
        ) : (
          <div className={style.select_section}>
            <div className={style.Org_Select}>
              <FormControl sx={{ width: "350px" }}>
                <InputLabel id="demo-simple-select-label">
                  Select Organization
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Select Organisations"
                  value={selectedOrgId}
                  onChange={(event) => {
                    const selectedOrgID = event.target.value;
                    setSelectedOrgId(selectedOrgID);
                    localStorage.setItem("selectedOrgID", selectedOrgID);
                    categoryGet(selectedOrgID);
                    setButtonshow(true);
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300, // Adjust the maximum height as needed
                      },
                    },
                  }}
                >
                  {organisationDetail && organisationDetail.length > 0 ? (
                    organisationDetail.map((org) => (
                      <MenuItem key={org.org_id} value={org.org_id}>
                        {org.org_name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No organisations available</MenuItem>
                  )}
                </Select>
              </FormControl>
            </div>
          </div>
        )}
      </div>

      <div className={style.menu}>
        {loading ? (
          <Loader />
        ) : mainCategoryShow ? ( // categories under club & org show list
          <div className={style.Grid}>
            {mainCategoryList &&
              mainCategoryList.length > 0 &&
              mainCategoryList.map((item, index) => {
                const truncatedName =
                  item.Name.length > 20
                    ? item.Name.substring(0, 20) + "..."
                    : item.Name;
                return (
                  <div key={index} className={style.Cards}>
                    <div className={style.cardContent}>
                      <p> {truncatedName}</p>
                    </div>
                    <div className={style.cardBottom}>
                      <Tooltip title="View">
                        <IconButton>
                          <HiOutlineViewGrid
                            className={style.viewButton}
                            onClick={() => {
                              setSubCategoryCreate(true);
                              localStorage.setItem(
                                "categoryID",
                                item.category_id
                              );
                              subcategoryGet();
                              fetchScheduledExams();
                              setCurrentPath(item.Name);
                              setClub_ID(item.club_id);
                            }}
                          />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Edit">
                        <IconButton>
                          <BiEdit
                            className={style.editButton}
                            onClick={(e) => {
                              e.stopPropagation();
                              setEditMainCategoryName(item.Name);
                              setEditMainCategoryDescription(item.Description);
                              setMainCategoryEdit(true);
                              setMainCategoryID(item.category_id);
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton>
                          <RiDeleteBinLine
                            className={style.deleteButton}
                            onClick={(e) => {
                              e.stopPropagation();
                              setMainCategoryDelete(true);
                              setMainCategoryID(item.category_id);
                              setDeleteName(truncatedName);
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                );
              })}
          </div>
        ) : (
          ""
        )}
      </div>

      {moduleCreatePopup && (
        <div className={style.modalOverlay}>
          <div className={style.modalContent}>
            <div className={style.moduleclose}>
              <AiOutlineClose
                onClick={() => {
                  setModuleCreatePopup(false);
                  setModuleName("");
                  setModuleDescription("");
                  setModuleState("disabled");
                  setModuleStatus("inactive");
                  setModuleType("free");
                }}
              />
            </div>
            <h3>Create Module</h3>
            <TextField
              label="Module Name"
              value={moduleName}
              autoComplete="off"
              onChange={(e) => setModuleName(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Module Description"
              autoComplete="off"
              value={moduleDescription}
              onChange={(e) => setModuleDescription(e.target.value)}
              fullWidth
              margin="normal"
            />

            <div className={style.ActionSwitch}>
              <FormGroup>
                {/* <p>STATE</p> */}
                <FormControlLabel
                  control={
                    <Switch
                      checked={moduleState === "enabled"}
                      onChange={handleStateChange}
                    />
                  }
                  label={moduleState === "enabled" ? "Enabled" : "Disabled"}
                  labelPlacement="bottom"
                />
              </FormGroup>

              <FormGroup>
                {/* <p>STATUS</p> */}
                <FormControlLabel
                  control={
                    <Switch
                      checked={moduleStatus === "active"}
                      onChange={handleStatusChange}
                    />
                  }
                  label={moduleStatus === "active" ? "Active" : "Inactive"}
                  labelPlacement="bottom"
                />
              </FormGroup>

              <FormGroup>
                {/* <p>TYPE</p> */}
                <FormControlLabel
                  control={
                    <Switch
                      checked={moduleType === "paid"}
                      onChange={handleTypeChange}
                    />
                  }
                  label={moduleType === "paid" ? "Paid" : "Free"}
                  labelPlacement="bottom"
                />
              </FormGroup>
            </div>

            <div className={style.modalButtons}>
              <button className={style.create} onClick={addModule}>
                Create
              </button>
              <button
                className={style.cancel}
                onClick={() => {
                  setModuleCreatePopup(false);
                  setModuleName("");
                  setModuleDescription("");
                  setModuleState("disabled");
                  setModuleStatus("inactive");
                  setModuleType("free");
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {scheduleExamModal && (
        <div className={style.examModalOverlay}>
          <div className={style.examModalContent}>
            <div className={style.examModuleclose}>
              <AiOutlineClose onClick={() => setScheduleExamModal(false)} />
            </div>
            <div className={style.popHead}>
              <h2>Schedule Exam</h2>
            </div>

            <div className={style.examtype}>
              <Box sx={{ minWidth: 220 }}>
                <FormControl fullWidth>
                  <InputLabel id="exam-type-select-label">Type</InputLabel>
                  <Select
                    labelId="exam-type-select-label"
                    id="exam-type-select"
                    value={examType}
                    label="Type"
                    onChange={handleExamTypeChange}
                  >
                    <MenuItem value="daily">Daily</MenuItem>
                    <MenuItem value="weekly">Weekly</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              {examType === "weekly" && (
                <div className={style.weekdays}>
                  <p> Set weekly exams every :</p>
                  <Box sx={{ minWidth: 500, marginTop: 1 }}>
                    <FormControl fullWidth>
                      <InputLabel id="day-select-label">Day</InputLabel>
                      <Select
                        labelId="day-select-label"
                        id="day-select"
                        value={selectedDay}
                        label="Day"
                        onChange={handleDayChange}
                      >
                        {daysOfWeek.map((day) => (
                          <MenuItem key={day} value={day}>
                            {day}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </div>
              )}

              <Box>
                <TextField
                  label="No of Questions"
                  type="number"
                  autoComplete="off"
                  fullWidth
                  margin="normal"
                  value={noOfQuestions}
                  onChange={handleNoOfQuestionsChange}
                  onInput={handleInput}
                />
              </Box>
            </div>

            <TextField
              label="Exam Name"
              autoComplete="off"
              fullWidth
              margin="normal"
              value={scheduleExamName}
              onChange={handleExamNameChange}
            />

            <div className={style.examModalButtons}>
              <button className={style.create} onClick={scheduleExams}>
                Create
              </button>
              <button
                className={style.cancel}
                onClick={() =>{ 
                  setScheduleExamModal(false)
                  setExamType("daily");
                  setSelectedDay("");
                  setNoOfQuestions("");
                  setScheduleExamName("");
                
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {scheduleExamEditPopup && (
        <div className={style.examModalOverlay}>
          <div className={style.examModalContent}>
            <div className={style.examModuleclose}>
              <AiOutlineClose onClick={() => setScheduleExamEditPopup(false)} />
            </div>
            <div className={style.popHead}>
              <h2>Edit Scheduled Exam</h2>
            </div>

            <form onSubmit={scheduleExamEdit}>
              <div className={style.examtype}>
                <Box sx={{ minWidth: 220 }}>
                  <FormControl fullWidth>
                    <InputLabel id="exam-type-select-label">Type</InputLabel>
                    <Select
                      labelId="exam-type-select-label"
                      id="exam-type-select"
                      name="scheduledexam_type"
                      value={formData.scheduledexam_type}
                      label="Type"
                      onChange={handleChange}
                    >
                      <MenuItem value="daily">Daily</MenuItem>
                      <MenuItem value="weekly">Weekly</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                {formData.scheduledexam_type === "weekly" && (
                  <div className={style.weekdays}>
                    <p> Set weekly exams every :</p>
                    <Box sx={{ minWidth: 500, marginTop: 1 }}>
                      <FormControl fullWidth>
                        <InputLabel id="day-select-label">Day</InputLabel>
                        <Select
                          labelId="day-select-label"
                          id="day-select"
                          name="selected_day"
                          value={formData.selected_day}
                          label="Day"
                          onChange={handleChange}
                        >
                          {[
                            "Monday",
                            "Tuesday",
                            "Wednesday",
                            "Thursday",
                            "Friday",
                            "Saturday",
                            "Sunday",
                          ].map((day) => (
                            <MenuItem key={day} value={day}>
                              {day}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                )}

                <Box>
                  <TextField
                    label="No of Questions"
                    type="number"
                    autoComplete="off"
                    fullWidth
                    margin="normal"
                    name="no_of_questions"
                    value={formData.no_of_questions}
                    onChange={handleChange}
                    onInput={handleInput}
                  />
                </Box>
              </div>

              <TextField
                label="Exam Name"
                autoComplete="off"
                fullWidth
                margin="normal"
                name="scheduledexam_name"
                value={formData.scheduledexam_name}
                onChange={handleChange}
              />

              <div className={style.examModalButtons}>
                <button type="submit" className={style.create}>
                  Update
                </button>
                <button
                  type="button"
                  className={style.cancel}
                  onClick={() => setScheduleExamEditPopup(false)}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {moduleEditPopup && (
        <div className={style.modalOverlay}>
          <div className={style.modalContent}>
            <div className={style.moduleclose}>
              <AiOutlineClose
                onClick={() => {
                  setModuleEditPopup(false);
                  localStorage.removeItem("moduleId");
                }}
              />
            </div>
            <h3>{moduleEditName} MODULE PERMISSIONS</h3>

            <div className={style.ActionSwitch}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={moduleEditState === "enabled"}
                      onChange={handleEditStateChange}
                    />
                  }
                  label={moduleEditState === "enabled" ? "Enabled" : "Disabled"}
                  labelPlacement="bottom"
                />
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={moduleEditStatus === "active"}
                      onChange={handleEditStatusChange}
                    />
                  }
                  label={moduleEditStatus === "active" ? "Active" : "Inactive"}
                  labelPlacement="bottom"
                />
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={moduleEditType === "paid"}
                      onChange={handleEditTypeChange}
                    />
                  }
                  label={moduleEditType === "paid" ? "Paid" : "Free"}
                  labelPlacement="bottom"
                />
              </FormGroup>
            </div>
          </div>
        </div>
      )}

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        sx={{
          position: "left",
          "& .MuiSnackbar-root": {
            borderRadius: 8, // Optional rounded corners
          },
        }}
      >
        <MuiAlert
          onClose={handleCloseSnackbar}
          variant="filled"
          severity="success" // Adjust severity for color based on message type (optional)
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>

      {mainCategoryCreate ? ( // main category create popup
        <>
          <div
            onClick={() => {
              setMainCategoryCreate(false);
            }}
            className={style.Overlay}
          ></div>
          <div className={style.Category_Create}>
            <div className={style.categoryClose}>
              <AiOutlineClose
                onClick={() => {
                  setMainCategoryCreate(false);
                }}
              />
            </div>
            <div className={style.categorySection}>
              <div className={style.categoryName}>
                <label htmlFor="">Course Category</label>
                <input
                  type="text"
                  name=""
                  id=""
                  required
                  onChange={(e) => {
                    setMainCategoryName(e.target.value);
                  }}
                  placeholder=""
                />
              </div>
              <div className={style.categoryDescription}>
                <label htmlFor="">Description</label>
                <input
                  type="text"
                  name=""
                  id=""
                  required
                  onChange={(e) => {
                    setMainCategoryDescription(e.target.value);
                  }}
                  placeholder=""
                />
              </div>
            </div>
            <div className={style.popupButton}>
              <button
                onClick={() => {
                  mainCategory_Create();
                }}
                className={style.create}
              >
                Create
              </button>
              <button
                className={style.cancel}
                onClick={() => {
                  setMainCategoryCreate(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {mainCategoryEdit ? ( // main category create popup
        <>
          <div
            onClick={() => {
              setMainCategoryEdit(false);
            }}
            className={style.Overlay}
          ></div>
          <div className={style.Category_Create}>
            <div className={style.categoryClose}>
              <AiOutlineClose
                onClick={() => {
                  setMainCategoryEdit(false);
                }}
              />
            </div>
            <div className={style.categorySection}>
              <div className={style.categoryName}>
                <label htmlFor="">Course Category</label>
                <input
                  type="text"
                  name=""
                  id=""
                  required
                  value={editMainCategoryName}
                  onChange={(e) => {
                    setEditMainCategoryName(e.target.value);
                  }}
                />
              </div>
              <div className={style.categoryDescription}>
                <label htmlFor="">Description</label>
                <input
                  type="text"
                  name=""
                  id=""
                  required
                  value={editMainCategoryDescription}
                  onChange={(e) => {
                    setEditMainCategoryDescription(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className={style.popupButton}>
              <button
                onClick={() => {
                  editMainCategory();
                }}
                className={style.create}
              >
                Update
              </button>
              <button
                className={style.cancel}
                onClick={() => {
                  setMainCategoryEdit(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {mainCategoryDelete ? (
        <>
          <div
            className={style.Overlay}
            onClick={() => {
              setMainCategoryDelete(false);
            }}
          ></div>
          <div className={style.DeletePopup}>
            <div className={style.deleteClose}>
              <AiOutlineClose
                onClick={() => {
                  setMainCategoryDelete(false);
                }}
              />
            </div>
            <div className={style.deleteSection}>
              <div className={style.popupText}>
                <p>
                  Are you sure you want to delete <span>{deleteName}</span>?
                </p>
              </div>
              <div className={style.deleteButtons}>
                <button
                  className={style.orgDelete}
                  onClick={() => {
                    deleteMainCategory();
                  }}
                >
                  Delete
                </button>

                <button
                  className={style.orgDeleteCancel}
                  onClick={() => {
                    setMainCategoryDelete(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {subCategoryCreate ? ( // create sub categories in main categories
        <>
          <div className={style.Overlay}></div>
          <div className={style.SubcategoryPopup}>
            <div className={style.Heading}>
              <div className={style.headLeft}>
                <div className={style.navigationLinks}>
                  <div className={style.linkLeft}>
                    <p
                      onClick={() => {
                        setSubCategoryCreate(false);
                        setSubCategoryList([]);
                        setTopicList([]);
                        setSelectedCategories([]);
                        setDivide(false);
                      }}
                    >
                      {currentPath}
                    </p>
                  </div>
                  {divide && selectedCategories.length > 0 ? (
                    <span className={style.divide}>/</span>
                  ) : (
                    ""
                  )}

                  {selectedCategories.map((category, index) => (
                    <div className={style.linkRight} key={category.category_id}>
                      <span>{index > 0 && "/"}</span>
                      <p
                        onClick={() =>
                          handleBreadcrumbClick(category.parent_id, index)
                        }
                      >
                        {category.Name}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
              <div className={style.headRight}>
                <div className={style.Buttons}>
                  <button
                    onClick={() => setModuleCreatePopup(true)}
                    className={style.subButton}
                  >
                    Add Module
                  </button>

                  <button
                    onClick={() => setScheduleExamModal(true)}
                    className={style.subButton}
                  >
                    Schedule Exam
                  </button>

                  {categoryButtonShow ? (
                    <button
                      onClick={() => {
                        setSubCategoryCreatePopup(true);
                      }}
                      className={style.subButton}
                    >
                      Create SubCategory
                    </button>
                  ) : (
                    ""
                  )}

                  {topicButtonShow ? (
                    <button
                      className={style.topicButton}
                      onClick={() => {
                        setcategoryButtonShow(false);
                        setTopicCreation(true);
                        fetchModuleList();
                        localStorage.setItem(
                          "selectedModuleId",
                          "1gn001tp1c002nom0d"
                        );
                        localStorage.setItem(
                          "selectedModuleName",
                          "GENERAL TOPICS"
                        );
                      }}
                    >
                      Create Topic
                    </button>
                  ) : (
                    ""
                  )}
                  {/* {showExamButton ? (
                    <button
                      className={style.topicButton}
                      onClick={() => {
                        setExamCreate(true);
                      }}
                    >
                      Create Exam
                    </button>
                  ) : (
                    ""
                  )} */}
                </div>
                <div className={style.close}>
                  <AiOutlineClose
                    onClick={() => {
                      setSubCategoryCreate(false);
                      setSubCategoryList([]);
                      setTopicList([]);
                      setSelectedCategories([]);
                      setDivide(false);
                      setShowExamButton(false);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className={style.schedulexam}>
              {scheduleExamData.map((exam) => (
                <div
                  key={exam.scheduledexam_id}
                  className={style.scheduleExamBox}
                >
                  <div>
                    <h3>{exam.scheduledexam_name}</h3>
                  </div>
                  <div className={style.examActions}>
                    <div className={style.schDelete}>
                      <RiDeleteBin5Line
                        onClick={() => {
                          setDeleteScheduleExam(true);
                          localStorage.setItem(
                            "scheduledexam_id",
                            exam.scheduledexam_id
                          );
                        }}
                      />
                    </div>
                    <div className={style.schEdit}>
                      <MdEditSquare
                        onClick={() => {
                          setScheduleExamEditPopup(true);
                          localStorage.setItem(
                            "scheduledexam_name",
                            exam.scheduledexam_name
                          );
                          localStorage.setItem(
                            "scheduledexam_id",
                            exam.scheduledexam_id
                          );
                          localStorage.setItem(
                            "scheduledexam_type",
                            exam.scheduledexam_type
                          );
                          localStorage.setItem(
                            "selected_day",
                            exam.selected_day
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className={style.subBottom}>
              {subShow ? (
                <div className={style.SubGrid}>
                  {subCategoryList.length > 0 &&
                    subCategoryList.map((list) => {
                      const truncatedName =
                        list.Name.length > 20
                          ? list.Name.substring(0, 20) + "..."
                          : list.Name;
                      return (
                        <div className={style.SubCards}>
                          <div className={style.SubcardContent}>
                            <p> {truncatedName}</p>
                          </div>
                          <div className={style.bottom_left}>
                            <Tooltip title="View">
                              <IconButton>
                                <HiOutlineViewGrid
                                  className={style.viewButton}
                                  onClick={() => {
                                    localStorage.setItem(
                                      "categoryID",
                                      list.category_id
                                    );
                                    subcategoryGet();
                                    handleCategoryClick(list);
                                    setDivide(true);
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Edit">
                              <IconButton>
                                <BiEdit
                                  className={style.editButton}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setSubCategoryEdit(true);
                                    setEditSubCategoryName(list.Name);
                                    setEditSubCategoryDescription(
                                      list.Description
                                    );
                                    setSubCategoryID(list.category_id);
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                              <IconButton>
                                <RiDeleteBinLine
                                  className={style.deleteButton}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setSubCategoryDelete(true);
                                    setSubCategoryID(list.category_id);
                                    setDeleteSubName(list.Name);
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>
                      );
                    })}
                </div>
              ) : (
                ""
              )}
              {topicShow ? (
                <div className={style.list}>
                  <List sx={{ width: "100%" }}>
                    {topicList &&
                      topicList.map((moduleItem) => (
                        <div key={moduleItem.module_id}>
                          <div className={style.moduleHead}>
                            <h3>{moduleItem.module_name}</h3>

                            <div className={style.moduleActions}>
                              {moduleItem.module_id !==
                                "1gn001tp1c002nom0d" && (
                                <div
                                  className={style.editModule}
                                  onClick={() => {
                                    setModuleEditPopup(true);
                                    localStorage.setItem(
                                      "moduleId",
                                      moduleItem.module_id
                                    );
                                    fetchModuleData();
                                  }}
                                >
                                  <GrUserAdmin />
                                </div>
                              )}
                            </div>
                          </div>
                          {moduleItem.topics.map((topicItem) => (
                            <ListItem
                              key={topicItem.id}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
                                "&:hover": {
                                  backgroundColor: "#f5f5f5",
                                },
                              }}
                            >
                              <ListItemText
                                sx={{
                                  fontSize: "1.5rem",
                                  textTransform: "capitalize",
                                  alignItems: "center",
                                }}
                              >
                                {topicItem.type === "exam" ? (
                                  <PiExam className={style.ExamICon} />
                                ) : (
                                  <BsBookHalf className={style.ExamICon} />
                                )}
                                {topicItem.value}
                              </ListItemText>

                              <div className={style.TableButtons}>
                                {topicItem.type === "exam" ? (
                                  <>
                                    <button
                                      className={style.ViewTopic}
                                      onClick={() => {
                                        localStorage.setItem(
                                          "SelectedOrgID",
                                          moduleItem.org_id
                                        );
                                        localStorage.setItem(
                                          "SelectedTopicID",
                                          moduleItem.topic_id
                                        );
                                        localStorage.setItem(
                                          "SelectedsubTopicID",
                                          topicItem.id
                                        );
                                        localStorage.setItem(
                                          "SelectedsubTopicName",
                                          topicItem.value
                                        );
                                        navigate("/exams", {
                                          state: {
                                            organization: selectedOrgId,
                                            mainTopic: mainTopicId,
                                            topicName: topicItem.value,
                                            examId: topicItem.id,
                                            subcategories: selectedCategories,
                                            mainCategory: currentPath,
                                          },
                                        }
                                        )
                                        ;
                                      }}
                                    >
                                      View
                                    </button>

                                    <button
                                      className={style.UpdateTopic}
                                      onClick={() => {
                                        setEditExam(true);
                                        setEditExamName(topicItem.value);
                                        setEditExamTopicList(moduleItem.topics);
                                        setExamID(topicItem.id);
                                        setEditExamStatus(
                                          topicItem.publish_status
                                        );
                                      }}
                                    >
                                      Edit
                                    </button>
                                    <button
                                      className={style.DeleteTopic}
                                      onClick={() => {
                                        setDeleteExam(true);
                                        setExamID(topicItem.id);
                                        setDeleteTopicName(topicItem.value);
                                      }}
                                    >
                                      Delete
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <button
                                      className={style.asessments}
                                      onClick={() => {
                                         localStorage.setItem(
                                          "SelectedOrgID",
                                          moduleItem.org_id
                                        );
                                        localStorage.setItem(
                                          "SelectedTopicID",
                                          moduleItem.topic_id
                                        );
                                        localStorage.setItem(
                                          "SelectedsubTopicID",
                                          topicItem.id
                                        );
                                        localStorage.setItem(
                                          "SelectedsubTopicName",
                                          topicItem.value
                                        );
                                        navigate("/asessments", {
                                          state: {
                                            organization: selectedOrgId,
                                            mainTopic: mainTopicId,
                                            topicName: topicItem.value,
                                            topicId: topicItem.id,
                                            subcategories: selectedCategories,
                                            mainCategory: currentPath,
                                          },
                                        });
                                      }}
                                    >
                                      Assessments
                                    </button>
                                    <button
                                      className={style.ViewTopic}
                                      onClick={() => {
                                        localStorage.setItem(
                                          "SelectedOrgID",
                                          moduleItem.org_id
                                        );
                                        localStorage.setItem(
                                          "SelectedTopicID",
                                          moduleItem.topic_id
                                        );
                                        localStorage.setItem(
                                          "SelectedsubTopicID",
                                          topicItem.id
                                        );
                                        localStorage.setItem(
                                          "SelectedsubTopicName",
                                          topicItem.value
                                        );
                                        navigate("/lesson/create/admin", {
                        
                                          state: {
                                            organization: selectedOrgId,
                                            // mainTopic: mainTopicId,
                                            topicName: topicItem.value,
                                            topicId: topicItem.id,
                                            subcategories: selectedCategories,
                                            mainCategory: currentPath,
                                            // topics:topicList
                                          },
                                        });
                                      }}
                                    >
                                      View
                                    </button>

                                    <button
                                      className={style.UpdateTopic}
                                      onClick={() => {
                                        setEditData(topicItem.value);
                                        setEditId(topicItem.id);
                                        setTopicEdit(true);
                                        localStorage.setItem(
                                          "SelectedOrgID",
                                          moduleItem.org_id
                                        );
                                        localStorage.setItem(
                                          "SelectedTopicID",
                                          moduleItem.topic_id
                                        );
                                        localStorage.setItem(
                                          "SelectedsubTopicID",
                                          topicItem.id
                                        );
                                        localStorage.setItem(
                                          "SelectedsubTopicName",
                                          topicItem.value
                                        );
                                      }}
                                    >
                                      Edit
                                    </button>
                                    <button
                                      className={style.DeleteTopic}
                                      onClick={() => {
                                        setTopicDelete(true);
                                        localStorage.setItem(
                                          "SelectedOrgID",
                                          moduleItem.org_id
                                        );
                                        localStorage.setItem(
                                          "SelectedTopicID",
                                          moduleItem.topic_id
                                        );
                                        localStorage.setItem(
                                          "SelectedsubTopicID",
                                          topicItem.id
                                        );
                                        localStorage.setItem(
                                          "SelectedsubTopicName",
                                          topicItem.value
                                        );
                                      }}
                                    >
                                      Delete
                                    </button>
                                  </>
                                )}
                              </div>
                            </ListItem>
                          ))}
                        </div>
                      ))}
                  </List>
                </div>
              ) : (
                ""
              )}
            </div>

            {subCategoryCreatePopup ? ( // sub category create popup
              <>
                <div
                  onClick={() => {
                    setSubCategoryCreatePopup(false);
                  }}
                  className={style.secondOverlay}
                ></div>
                <div className={style.SubCategory_Create}>
                  <div className={style.subcategoryClose}>
                    <AiOutlineClose
                      onClick={() => {
                        setSubCategoryCreatePopup(false);
                      }}
                    />
                  </div>
                  <div className={style.categorySection}>
                    <div className={style.categoryName}>
                      <label htmlFor="">SubCategory Name</label>
                      <input
                        type="text"
                        name=""
                        id=""
                        required
                        onChange={(e) => {
                          setSubCategoryName(e.target.value);
                        }}
                        placeholder=""
                      />
                    </div>
                    <div className={style.categoryDescription}>
                      <label htmlFor="">Description</label>
                      <input
                        type="text"
                        name=""
                        id=""
                        required
                        onChange={(e) => {
                          setSubCategoryDescription(e.target.value);
                        }}
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className={style.popupButton}>
                    <button
                      onClick={() => {
                        subCatregory_create();
                      }}
                      className={style.create}
                    >
                      Create
                    </button>
                    <button
                      className={style.cancel}
                      onClick={() => {
                        setSubCategoryCreatePopup(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            {subCategoryEdit ? ( // sub categroy edit popup
              <>
                <div
                  onClick={() => {
                    setSubCategoryEdit(false);
                  }}
                  className={style.secondOverlay}
                ></div>
                <div className={style.SubCategory_Create}>
                  <div className={style.subcategoryClose}>
                    <AiOutlineClose
                      onClick={() => {
                        setSubCategoryEdit(false);
                      }}
                    />
                  </div>
                  <div className={style.categorySection}>
                    <div className={style.categoryName}>
                      <label htmlFor="">SubCategory Name</label>
                      <input
                        type="text"
                        name=""
                        id=""
                        required
                        value={editSubCategoryName}
                        onChange={(e) => {
                          setEditSubCategoryName(e.target.value);
                        }}
                      />
                    </div>
                    <div className={style.categoryDescription}>
                      <label htmlFor="">Description</label>
                      <input
                        type="text"
                        name=""
                        id=""
                        required
                        value={editSubCategoryDescription}
                        onChange={(e) => {
                          setEditSubCategoryDescription(e.target.value);
                        }}
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className={style.popupButton}>
                    <button
                      onClick={() => {
                        editSubCategory();
                      }}
                      className={style.create}
                    >
                      Update
                    </button>
                    <button
                      className={style.cancel}
                      onClick={() => {
                        setSubCategoryEdit(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            {subCategoryDelete ? (
              <>
                <div
                  className={style.secondOverlay}
                  onClick={() => {
                    setSubCategoryDelete(false);
                  }}
                ></div>
                <div className={style.DeletePopup}>
                  <div className={style.deleteClose}>
                    <AiOutlineClose
                      onClick={() => {
                        setSubCategoryDelete(false);
                      }}
                    />
                  </div>
                  <div className={style.deleteSection}>
                    <div className={style.popupText}>
                      <p>
                        Are you sure you want to delete{" "}
                        <span>{deleteSubName}</span>?
                      </p>
                    </div>
                    <div className={style.deleteButtons}>
                      <button
                        className={style.orgDelete}
                        onClick={() => {
                          deleteSubCategory();
                        }}
                      >
                        Delete
                      </button>

                      <button
                        className={style.orgDeleteCancel}
                        onClick={() => {
                          setSubCategoryDelete(false);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            {topicCreation ? (
              <>
                <div
                  onClick={() => {
                    setTopicCreation(false);
                  }}
                  className={style.secondOverlay}
                ></div>
                <div className={style.topicCreate}>
                  <div className={style.topicClose}>
                    <AiOutlineClose
                      onClick={() => {
                        setTopicCreation(false);
                        localStorage.removeItem("selectedModuleId");
                        localStorage.removeItem("selectedModuleName");
                        setSelectedModule("");
                      }}
                    />
                  </div>
                  <div className={style.topicSection}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Select Module
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Select Module"
                        value={selectedModule}
                        onChange={handleModuleChange}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 200,
                            },
                          },
                        }}
                      >
                        <MenuItem value="" disabled>
                          Select a module
                        </MenuItem>

                        {moduleList.map((module) => (
                          <MenuItem
                            key={module.module_id}
                            value={module.module_id}
                          >
                            {module.module_name}
                          </MenuItem>
                        ))}
                      </Select>

                      <button
                        className={style.moduleclear}
                        onClick={() => {
                          localStorage.setItem(
                            "selectedModuleId",
                            "1gn001tp1c002nom0d"
                          );
                          localStorage.setItem(
                            "selectedModuleName",
                            "GENERAL TOPICS"
                          );
                          setSelectedModule("");
                        }}
                      >
                        {" "}
                        clear module selection{" "}
                      </button>
                    </FormControl>

                    <div className={style.topicTextArea}>
                      <TextField
                        id="outlined-basic"
                        multiline
                        rows={4}
                        maxRows={5}
                        label="Topic Name"
                        variant="outlined"
                        onChange={(e) => {
                          setTopicName(e.target.value);
                        }}
                        sx={{ width: "100%", fontSize: "1.5rem" }}
                      />
                    </div>
                    <div className={style.popupButton}>
                      <button
                        onClick={() => {
                          topicAdd();
                        }}
                        className={style.create}
                      >
                        Create
                      </button>
                      <button
                        className={style.cancel}
                        onClick={() => {
                          setTopicCreation(false);
                          localStorage.removeItem("selectedModuleId");
                          localStorage.removeItem("selectedModuleName");
                          setSelectedModule("");
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            {topiEdit ? (
              <>
                <div
                  className={style.secondOverlay}
                  onClick={() => {
                    setTopicEdit(false);
                    removeItemsFromLocalStorage();
                  }}
                ></div>
                <div className={style.topicCreate}>
                  <div className={style.topicClose}>
                    <AiOutlineClose
                      onClick={() => {
                        setTopicEdit(false);
                        removeItemsFromLocalStorage();
                      }}
                    />
                  </div>

                  <div className={style.topicSection}>
                    <div className={style.topicTextArea}>
                      <TextField
                        id="outlined-basic"
                        multiline
                        rows={3}
                        maxRows={4}
                        value={editData}
                        label="Topic Name"
                        variant="outlined"
                        onChange={(e) => {
                          setEditData(e.target.value);
                        }}
                        sx={{ width: "100%" }}
                      />
                    </div>
                    <div className={style.popupButton}>
                      <button
                        onClick={() => {
                          editTopic();
                        }}
                        className={style.create}
                      >
                        Update
                      </button>
                      <button
                        className={style.cancel}
                        onClick={() => {
                          setTopicEdit(false);
                          removeItemsFromLocalStorage();
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            {topicDelete ? (
              <>
                <div
                  className={style.secondOverlay}
                  onClick={() => {
                    setTopicDelete(false);
                    removeItemsFromLocalStorage();
                  }}
                ></div>
                <div className={style.DeletePopup}>
                  <div className={style.deleteClose}>
                    <AiOutlineClose
                      onClick={() => {
                        setTopicDelete(false);
                        removeItemsFromLocalStorage();
                      }}
                    />
                  </div>
                  <div className={style.deleteSection}>
                    <div className={style.popupText}>
                      <p>
                        Are you sure you want to delete{" "}
                        <span>{deleteTopicName}</span>?
                      </p>
                    </div>
                    <div className={style.deleteButtons}>
                      <button
                        className={style.orgDelete}
                        onClick={() => {
                          deleteTopic();
                          setTopicDelete(false);
                        }}
                      >
                        Delete
                      </button>

                      <button
                        className={style.orgDeleteCancel}
                        onClick={() => {
                          setTopicDelete(false);
                          removeItemsFromLocalStorage();
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            {examCreate ? ( // sub category create popup
              <>
                <div
                  onClick={() => {
                    setExamCreate(false);
                    setExamStatus("draft");
                  }}
                  className={style.secondOverlay}
                ></div>
                <div className={style.SubCategory_Create}>
                  <div className={style.subcategoryClose}>
                    <AiOutlineClose
                      onClick={() => {
                        setExamCreate(false);
                      }}
                    />
                  </div>
                  <div className={style.categorySection}>
                    <div className={style.categoryName}>
                      <label htmlFor="">Exam Name</label>
                      <input
                        type="text"
                        name=""
                        id=""
                        required
                        value={examName}
                        onChange={(e) => {
                          setExamName(e.target.value);
                        }}
                        placeholder=""
                      />
                    </div>
                    <>
                      <FormControl sx={{ width: "100%", margin: "0.25rem" }}>
                        <InputLabel id="demo-simple-select-label">
                          Select Topics
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Select Topics"
                          multiple
                          value={examTopicList}
                          onChange={(event) =>
                            setExamTopicList(event.target.value)
                          } // Add this prop
                        >
                          {topicList &&
                            topicList.length > 0 &&
                            topicList.map((topic) => {
                              return topic.topics
                                .filter((subTopic) => subTopic.type !== "exam")
                                .map((subTopic) => {
                                  const isSelected = examTopicList.includes(
                                    subTopic.id
                                  );
                                  console.log("Rendering id:", topic.topic_id);
                                  console.log(
                                    "Rendering name:",
                                    topic.module_name
                                  );

                                  return (
                                    <MenuItem
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                      key={subTopic.id}
                                      value={subTopic.id}
                                    >
                                      {subTopic.value}
                                      <Checkbox
                                        name={subTopic.value}
                                        id={subTopic.id}
                                        checked={isSelected}
                                        onChange={() => {
                                          handleTopicSelection(subTopic.id);
                                        }}
                                        onClick={() => {
                                          localStorage.setItem(
                                            "examTopicID",
                                            topic.topic_id
                                          );
                                        }}
                                      />
                                    </MenuItem>
                                  );
                                });
                            })}
                        </Select>
                      </FormControl>
                    </>
                    <FormControl sx={{ width: "100%", margin: "0.5rem 0" }}>
                      <InputLabel id="demo-simple-select-label">
                        Select status
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Select status"
                        value={examStatus}
                        onChange={(e) => {
                          setExamStatus(e.target.value);
                        }}
                      >
                        <MenuItem value={"draft"}>Draft</MenuItem>
                        <MenuItem value={"publish"}>Publish</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className={style.popupButton}>
                    <button
                      onClick={() => {
                        examCreation();
                      }}
                      className={style.create}
                    >
                      Create
                    </button>
                    <button
                      className={style.cancel}
                      onClick={() => {
                        setExamCreate(false);
                        setExamStatus("draft");
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            {editExam ? ( // sub category create popup
              <>
                <div
                  onClick={() => {
                    setEditExam(false);
                  }}
                  className={style.secondOverlay}
                ></div>
                <div className={style.SubCategory_Create}>
                  <div className={style.subcategoryClose}>
                    <AiOutlineClose
                      onClick={() => {
                        setEditExam(false);
                      }}
                    />
                  </div>
                  <div className={style.categorySection}>
                    <div className={style.categoryName}>
                      <label htmlFor="">Exam Name</label>
                      <input
                        type="text"
                        name=""
                        id=""
                        required
                        value={editExamName}
                        onChange={(e) => {
                          setEditExamName(e.target.value);
                        }}
                        placeholder=""
                      />
                    </div>
                    <>
                      <FormControl sx={{ width: "100%", margin: "0.25rem" }}>
                        <InputLabel id="demo-simple-select-label">
                          Select Topics
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Select Topics"
                          multiple
                          value={editExamTopicList}
                          onChange={(event) =>
                            setEditExamTopicList(event.target.value)
                          } // Add this prop
                        >
                          {topicList &&
                            topicList.length > 0 &&
                            topicList.map((topic) => {
                              return topic.topics
                                .filter((subTopic) => subTopic.type !== "exam")
                                .map((subTopic) => {
                                  const isSelected = examTopicList.includes(
                                    subTopic.id
                                  );
                                  // console.log("Rendering id:", topic.topic_id);
                                  // console.log(
                                  //   "Rendering name:",
                                  //   topic.module_name
                                  // );

                                  return (
                                    <MenuItem
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                      key={subTopic.id}
                                      value={subTopic.id}
                                    >
                                      {subTopic.value}
                                      <Checkbox
                                        name={subTopic.value}
                                        id={subTopic.id}
                                        checked={isSelected}
                                        onChange={() => {
                                          handleTopicSelection(subTopic.id);
                                        }}
                                        onClick={() => {
                                          localStorage.setItem(
                                            "examTopicID",
                                            topic.topic_id
                                          );
                                        }}
                                      />
                                    </MenuItem>
                                  );
                                });
                            })}
                        </Select>
                      </FormControl>
                    </>
                    <FormControl sx={{ width: "100%", margin: "0.5rem 0" }}>
                      <InputLabel id="demo-simple-select-label">
                        Select status
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Select status"
                        value={editExamStatus}
                        onChange={(e) => {
                          setEditExamStatus(e.target.value);
                        }}
                      >
                        <MenuItem value={"draft"}>Draft</MenuItem>
                        <MenuItem value={"publish"}>Publish</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className={style.popupButton}>
                    <button
                      onClick={() => {
                        examUpdation();
                      }}
                      className={style.create}
                    >
                      Update
                    </button>
                    <button
                      className={style.cancel}
                      onClick={() => {
                        setEditExam(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            {deleteExam ? (
              <>
                <div
                  className={style.secondOverlay}
                  onClick={() => {
                    setDeleteExam(false);
                  }}
                ></div>
                <div className={style.DeletePopup}>
                  <div className={style.deleteClose}>
                    <AiOutlineClose
                      onClick={() => {
                        setDeleteExam(false);
                      }}
                    />
                  </div>
                  <div className={style.deleteSection}>
                    <div className={style.popupText}>
                      <p>
                        Are you sure you want to delete{" "}
                        <span>{deleteTopicName}</span>?
                      </p>
                    </div>
                    <div className={style.deleteButtons}>
                      <button
                        className={style.orgDelete}
                        onClick={() => {
                          examDelete();
                        }}
                      >
                        Delete
                      </button>
                      <button
                        className={style.orgDeleteCancel}
                        onClick={() => {
                          setDeleteExam(false);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}

            {deleteScheduleExam ? (
              <>
                <div
                  className={style.secondOverlay}
                  onClick={() => {
                    setDeleteScheduleExam(false);
                  }}
                ></div>
                <div className={style.DeletePopup}>
                  <div className={style.deleteClose}>
                    <AiOutlineClose
                      onClick={() => {
                        setDeleteScheduleExam(false);
                      }}
                    />
                  </div>
                  <div className={style.deleteSection}>
                    <div className={style.popupText}>
                      <p>Are you sure you want to delete</p>
                    </div>
                    <div className={style.deleteButtons}>
                      <button
                        className={style.orgDelete}
                        onClick={() => {
                          handleDeleteScheduledExam();
                        }}
                      >
                        Delete
                      </button>
                      <button
                        className={style.orgDeleteCancel}
                        onClick={() => {
                          setDeleteScheduleExam(false);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default Learning;
